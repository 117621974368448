import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config.json';

const Submenu = (props) => {
  const getProductInfo = (product) => config.products[product].productInfo;

  const btnSubMenu = (p) => {
    console.log('btnSubMenu: ' + config.products[p].productInfo.name);
    console.log(config.products[p].price);
    if (config.products[p].price && !Array.isArray(config.products[p].price)) {
      if (config.products[p].unsaleable) {
        console.log('sin calcular');
        return null;
      }
      return (
        <a
          href="/"
          className="button button--secondary"
          onClick={(event) => props.onContractProd(p, event)}
        >
          Contratar
        </a>
      );
    }
    return (
      <a
        href="/"
        className="button button--secondary"
        onClick={(event) => props.onSelectedProd(p, event)}
      >
        Calcular
      </a>
    );
  };

  return (
    <div className="main-submenu-container">
      <div
        className={`main-submenu ${
          props.selectedOption !== undefined ? 'is-visible' : ''
        } `}
      >
        <div
          className={`main-submenu__option theme-${props.theme} ${
            props.selectedOption !== undefined ? 'is-opened' : ''
          }`}
        >
          <div className="main-submenu__separator"></div>
          <div className="main-submenu__title u-only-mobile">
            <span
              className="main-submenu__arrow"
              onClick={() => props.onSelectOption(undefined)}
            ></span>
            <div className="main-submenu__name">{props.title}</div>
            <div
              className={`main-submenu__icon icon icon--${props.theme}`}
            ></div>
          </div>
          <div className="main-submenu__content">
            {props.products.map((prod, i) => (
              <div
                key={i}
                className="main-submenu__item"
                onClick={(evt) => props.onLink(evt, prod.path)}
              >
                <h2 className="main-submenu__item-title">
                  {getProductInfo(prod.path).name}
                </h2>
                <div className="o-media main-submenu-media">
                  <div className="o-media__object main-submenu-media__object">
                    <span className="icon icon--arrow-up u-font-small"></span>
                  </div>
                  <div className="o-media__body">
                    <p
                      className="u-margin-bottom-small"
                      dangerouslySetInnerHTML={{
                        __html: getProductInfo(prod.path).description,
                      }}
                    ></p>
                    <div className="u-margin-bottom u-only-up-tablet">
                      <a href="" className="link">
                        Más información
                      </a>
                    </div>
                    <div className="u-text-center@md">
                      {btnSubMenu(prod.path)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

Submenu.propTypes = {
  theme: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  onSelectOption: PropTypes.func.isRequired,
  onSelectedProd: PropTypes.func.isRequired,
  selectedOption: PropTypes.number,
};

Submenu.defaultProps = {
  theme: '',
  title: '',
  products: [],
};

export default Submenu;
